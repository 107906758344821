<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="showModal"
      :key="'modalContent' + Number(showModal)"
      class="modal"
      :class="{ 'is-active': showModal }"
    >
      <div class="modal-background"></div>
      <div
        class="modal-card"
        :class="[
          'modal-card-' + modalSize,
          unsetFillModeCssClass,
          animateClass,
        ]"
      >
        <header class="modal-card-head">
          <p v-if="!hideHeader" class="modal-card-title">
            {{ modalTitle }}
          </p>
          <button
            v-if="!hideHeader"
            @click="closeModal()"
            class="delete"
            aria-label="close"
          ></button>
        </header>

        <section class="modal-card-body">
          <transition name="fade" mode="out-in">
            <div v-if="!isSavingSuccess && !isSavingError">
              <slot name="content"></slot>
            </div>

            <section
              v-if="isSavingSuccess"
              class="content-successMessage has-text-centered"
            >
              <SuccessAnimation class="has-margin-bottom-x2" />
              <div
                v-if="savingSuccessMessage && !isSavingError"
                class="title is-4"
                v-text="savingSuccessMessage"
              ></div>
              <slot name="successMessageSlot"></slot>
            </section>
            <section
              v-if="isSavingError"
              class="content-successMessage has-text-centered"
            >
              <ErrorAnimation class="has-margin-bottom-x2" />
              <div
                v-if="savingErrorMessage && !isSavingSuccess"
                class="title is-4"
                v-text="savingErrorMessage"
              ></div>
              <slot name="errorMessageSlot"></slot>
            </section>
          </transition>
        </section>

        <transition name="fade" mode="out-in">
          <footer v-if="!hideFooter" class="modal-card-foot">
            <ui-submit-form-controls
              v-if="!hideFooterButtons"
              :onClickSave="onClickSave"
              :onClickCancel="closeModal"
              :isSaving="isSaving"
              :disableSaveButton="disableSaveButton"
              :buttonLabelConfirm="buttonLabelConfirm"
              :buttonLabelCancel="buttonLabelCancel"
              :hideCancelButton="hideCancelButton"
              :hideSaveButton="hideSaveButton"
              :confirmButtonCssClass="confirmButtonCssClass"
              class="has-margin-bottom"
            />
          </footer>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import ErrorAnimation from '@/components/UI/Components/ErrorAnimation'
import SuccessAnimation from '@/components/UI/Components/SuccessAnimation'
export default {
  name: 'modal',

  components: {
    ErrorAnimation,
    SuccessAnimation,
  },

  props: {
    buttonLabelConfirm: {
      type: String,
      default: function() {
        return 'Save'
      },
    },
    buttonLabelCancel: {
      type: String,
      default: function() {
        return 'Cancel'
      },
    },
    confirmButtonCssClass: {
      type: String,
      default: 'is-success',
    },
    disableSaveButton: {
      type: Boolean,
      default: false,
    },

    isSaving: {
      type: Boolean,
      default: false,
    },

    isSavingSuccess: {
      type: Boolean,
      default: false,
    },
    savingSuccessMessage: {
      type: String,
      default: function() {
        return 'Data saved!'
      },
    },
    isSavingError: {
      type: Boolean,
      default: false,
    },
    savingErrorMessage: {
      type: String,
      default: function() {
        return 'Oops! Something went wrong!'
      },
    },

    modalTitle: {
      type: String,
      default: '',
    },
    onClickCancel: {
      type: Function,
      default: function() {},
      required: false,
    },
    onClickSave: {
      type: Function,
      default: function() {},
      required: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideFooterButtons: {
      type: Boolean,
      default: false,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Sizes are:
     * - dynamic (size will be determent by content)
     * - small (width is set to auto)
     * - normal (is bulma default)
     * - large (1024)
     * - extra-large (1200)
     */
    modalSize: {
      type: String,
      default: 'normal',
    },
  },

  data() {
    return {
      modalAnimation: this.showModal,
      animateTimer: null,
      unsetFillModeCssClass: 'unset-fill-mode',
    }
  },

  computed: {
    animateClass() {
      let cls = 'animated fade'
      cls = cls + (this.modalAnimation ? 'In' : 'Out')
      cls = cls + ' faster'
      return cls
    },
  },

  beforeDestroy() {
    /**
     * Destroy timer
     */
    if (this.animateTimer) {
      clearTimeout(this.animateTimer)
    }
  },

  methods: {
    closeModal() {
      this.unsetFillModeCssClass = ''
      this.modalAnimation = false
      this.animateTimer = setTimeout(this.onClickCancel, 0)
    },
  },
}
</script>

<style lang="scss">
.modal {
  .modal-card-body {
    position: relative;
    .vc-popover-content-wrapper {
      position: absolute !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.modal {
  z-index: 101;

  .modal-card {
    &.animated {
      animation-fill-mode: unset;
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: $gap;
    padding-right: $gap;
  }
  @media screen and (min-width: 769px) {
    .modal-card {
      &-dynamic {
        width: auto !important;
      }
      &-large {
        width: 100% !important;
        max-width: 990px;
        @media screen and (max-width: 1020px) {
          padding-left: $gap;
          padding-right: $gap;
        }
      }
      &-extra-large {
        width: 100% !important;
        max-width: 1200px;
        @media screen and (max-width: 1230px) {
          padding-left: $gap;
          padding-right: $gap;
        }
      }
    }
  }
  .modal-card-body {
    .vc-popover-content-wrapper {
      position: fixed !important;
    }
  }
  .modal-card-foot {
    .buttons {
      flex: 1;
    }
  }
}
</style>
